<template>
  <div class="page-end">
    <h1>
      <span>Опрос пройден!</span>
      <sub>Спасибо что уделили нам время!</sub>
    </h1>
    <div class="icon">
      <n-icon icon="check-circle" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageEnd',
}
</script>

<style lang="scss" scoped>
.page-end {
  text-align: center;

  h1 {
    margin: 0;
    padding: 70px 0 40px;
    font-size: 1.8em;
    sub {
      display: inline-block;
      width: 100%;
      margin-top: 10px;
      font-weight: lighter;
      font-size: 0.7em;
      line-height: 1.2;
    }
  }

  .icon {
    .n-icon {
      font-size: 6em;
      color: #008000;
    }
  }
}
</style>
